import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTheme } from '@chakra-ui/react'
import { IoBed, IoFlower, IoCloudyNight } from 'react-icons/io5'

import { FeatureItem, FeatureList, P } from '../ui'

function Features() {
  const { t } = useTranslation()

  return (
    <FeatureList columns={{ base: 1, md: 3, lg: 3 }}>
      <FeatureItem
        icon={<IoBed color="#bc945c" size="3rem" />}
        title={t('index:features.one.title')}
        desc={t('index:features.one.desc')}
      />
      <FeatureItem
        icon={<IoCloudyNight color="#bc945c" size="3rem" />}
        title={t('index:features.two.title')}
        desc={t('index:features.two.desc')}
      />
      <FeatureItem
        icon={<IoFlower color="#bc945c" size="3rem" />}
        title={t('index:features.three.title')}
        desc={t('index:features.three.desc')}
      />
    </FeatureList>
  )
}

export default Features
