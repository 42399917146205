import React from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button, HStack, Stack } from '@chakra-ui/react'

import Layout from '../components/layout'
import Rooms from '../components/rooms'
import Features from '../components/features'
import Services from '../components/services'
import ButtonBook from '../components/buttonBook'

import {
  Article,
  Section,
  HeroBody,
  HeroTitle,
  HeroSubtitle,
  HeroCta,
  RowContent,
  ColImage,
  ColInfo,
  P,
  FeaturedBanner,
  Carousel,
  Gallery,
  TestimonialItem,
  Testimonials,
  Image,
  HeroGallery,
  RoomList,
  ColVideo,
} from '../ui'

// import FoodCategories from '../components/foodCategories'

function IndexPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout title={t('index:title')}>
      <HeroGallery
        images={[
          <Image fluid={data.hero2.childImageSharp.fluid} layout="background" />,
          <Image fluid={data.hero3.childImageSharp.fluid} layout="background" />,
          <Image fluid={data.hero4.childImageSharp.fluid} layout="background" />,
          <Image fluid={data.hero5.childImageSharp.fluid} layout="background" />,
        ]}
      >
        <HeroBody
          marginX={{ base: 'auto', md: 'initial' }}
          paddingBottom={{ base: 6, md: 10, lg: 0 }}
          textAlign={{ base: 'center', md: 'center' }}
        >
          <HeroTitle>{t('index:hero.title')}</HeroTitle>
          <HeroSubtitle>{t('index:hero.desc')}</HeroSubtitle>
          <HeroCta width="100%" display="flex" justifyContent={{ base: 'center', md: 'center' }}>
            <ButtonBook size="lg" pointerEvents="all" />
          </HeroCta>
        </HeroBody>
      </HeroGallery>

      <Article>
        <Section spacing={10}>
          <RowContent>
            <ColVideo url="https://www.youtube.com/watch?v=ovk_nPBigSY" />
            <ColInfo title={t('index:intro.title')} backgroundColor="gray.100">
              <P>{t('index:intro.desc1')}</P>
              <P>{t('index:intro.desc2')}</P>
              <ButtonBook />
            </ColInfo>
          </RowContent>
          <Features />
        </Section>

        <Section title={t('suites:title')} subtitle={t('suites:desc')} titleAlign="center">
          <Rooms />
        </Section>

        <Section title={t('index:services.title')} titleAlign="center">
          <Services />
        </Section>

        <Section title={t('gallery:galleries.experience.title')} titleAlign="center">
          <Gallery
            images={data.gallery.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>

        <Section spacing={10}>
          <RowContent>
            <ColImage
              desc={t('index:events.restaurant.title')}
              image={<Image fixed={data.featured3.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title={t('index:events.restaurant.title')} backgroundColor="gray.100">
              <P>{t('index:events.restaurant.desc')}</P>
              <ButtonBook />
            </ColInfo>
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo title={t('index:events.spa.title')} backgroundColor="gray.100">
              <P>{t('index:events.spa.desc')}</P>
              <Button
                colorScheme="primary"
                variant="outline"
                as={Link}
                to="/spa"
                width="fit-content"
                _hover={{ backgroundColor: 'transparent' }}
              >
                {t('index:events.buttons.spa')}
              </Button>
            </ColInfo>
            <ColImage
              desc={t('index:events.spa.title')}
              image={<Image fixed={data.featured4.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>

          <RowContent>
            <ColImage
              desc={t('index:events.event.title')}
              image={<Image fixed={data.featured1.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title={t('index:events.event.title')} backgroundColor="gray.100">
              <P>{t('index:events.event.desc')}</P>
              <Button
                colorScheme="primary"
                variant="outline"
                as={Link}
                to="/events"
                width="fit-content"
                _hover={{ backgroundColor: 'transparent' }}
              >
                {t('index:events.buttons.events')}
              </Button>
            </ColInfo>
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo title={t('index:events.weddings.title')} backgroundColor="gray.100">
              <P>{t('index:events.weddings.desc')}</P>
              <Button
                colorScheme="primary"
                variant="outline"
                as={Link}
                to="/weddings"
                width="fit-content"
                _hover={{ backgroundColor: 'transparent' }}
              >
                {t('index:events.buttons.weddings')}
              </Button>
            </ColInfo>
            <ColImage
              desc={t('index:events.weddings.title')}
              image={<Image fixed={data.featured2.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>

          <RowContent>
            <ColImage
              desc={t('tours:title')}
              image={<Image fixed={data.featured5.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title={t('tours:title')} backgroundColor="gray.100">
              <P>{t('tours:desc')}</P>
              <Button
                colorScheme="primary"
                variant="outline"
                as={Link}
                to="/explore"
                width="fit-content"
                _hover={{ backgroundColor: 'transparent' }}
              >
                {t('index:events.buttons.tours')}
              </Button>
            </ColInfo>
          </RowContent>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero1: file(relativePath: { eq: "index/hero1.jpeg" }) {
      ...bannerSectionImage
    }

    hero2: file(relativePath: { eq: "index/hero2.jpeg" }) {
      ...bannerSectionImage
    }

    hero3: file(relativePath: { eq: "index/hero3.jpeg" }) {
      ...bannerSectionImage
    }

    hero4: file(relativePath: { eq: "index/hero4.jpg" }) {
      ...bannerSectionImage
    }

    hero5: file(relativePath: { eq: "index/hero5.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "index/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    featured1: file(relativePath: { eq: "index/events.jpg" }) {
      ...fixedImage
    }

    featured2: file(relativePath: { eq: "index/weddings.jpg" }) {
      ...fixedImage
    }

    featured3: file(relativePath: { eq: "gallery/restaurant/Restaurante (2).jpg" }) {
      ...fixedImage
    }

    featured4: file(relativePath: { eq: "spa/featured.jpg" }) {
      ...fixedImage
    }

    featured5: file(
      relativePath: {
        eq: "tours/galleries/bacalar/57362489_604260966706568_6583381117473128448_n.jpg"
      }
    ) {
      ...fixedImage
    }

    house: file(relativePath: { eq: "index/hero3.jpeg" }) {
      ...fixedImage
    }

    vegan: file(relativePath: { eq: "index/hero3.jpeg" }) {
      ...fixedImage
    }

    temazcal: file(relativePath: { eq: "index/hero3.jpg" }) {
      ...fixedImage
    }

    spa: file(relativePath: { eq: "index/hero3.jpg" }) {
      ...fixedImage
    }
  }
`

export default IndexPage
